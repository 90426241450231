import './App.css';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import WeatherChart from './WeatherChart';
import WeatherMap from './WeatherMap';
import Footer from './Footer';
import { FaMapMarkerAlt, FaMap, FaListAlt, FaCog, FaInfoCircle } from 'react-icons/fa';
import { getAlert, getAlertText, getTemperatureColor, getUVColor, convertWindSpeed, convertTemperature, getDirectionFromAngle, getWindDescription, WeatherDescription, getWeatherDescription } from './WeatherUtils';
import { Helmet } from 'react-helmet';
import { useSwipeable } from 'react-swipeable';
import { Adsense } from '@ctrl/react-adsense';
//import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import WeatherProMaps from "./WeatherProMaps";


//const TRACKING_ID = "UA-4643300-1";
//const TRACKING_ID = "G-SNL1CE1MM4";

const App = () => {
  const [weather, setWeather] = useState(null); 
  const [location, setLocation] = useState(null); 
  const [themeMod, setThemeMod] = useState('dark'); 
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [geolocationConsent, setGeolocationConsent] = useState(null);
  const [tempUnits, setTempUnits] = useState('C'); 
  const [windSpeedUnits, setWindSpeedUnits] = useState('m/s'); 
  const [userLang, setUserLang] = useState('en'); 
  const [isLoading, setIsLoading] = useState(false); 
  const [isMapOpen, setIsMapOpen] = useState(false);
  
  
  const [notificationCityName, setNotificationCityName] = useState(localStorage.getItem('NotificationCityName') || 'No city selected');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipAlert, setShowTooltipAlert] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [tooltipData, setTooltipData] = useState(null);
  
  const [searchTerm, setSearchTerm] = useState(''); 
  const [searchResults, setSearchResults] = useState([]); 
  
  const [dateTime, setDateTime] = useState(''); 
  const [currentHour, setCurrentHour] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [forecastForCurrentTime, setForecastForCurrentTime] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  
  const [cityList, setCityList] = useState(JSON.parse(localStorage.getItem('cityList')) || []);
  const tooltipRef = useRef(null);
  const [isMyLocationID, setIsMyLocationID] = useState(localStorage.getItem('isMyLocationID') || null);
  
  const dateRefs = useRef({}); 
  const [movieUrl, setMovieUrl] = useState('');
  const [movieUrlTmp, setMovieUrlTmp] = useState('');
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  
  const [showTooltipProMap, setShowTooltipProMap] = useState(false);
  
  //const [showAds, setShowAds] = useState([false, false, false, false]);
  
  const handleFullScreenChange = (isFullScreenState) => {
	
    setIsFullScreen(isFullScreenState); 
  };
  
  
  const [showDonateInfo, setShowDonateInfo] = useState(false);
  
  const messages = {
    en: {
      title: "Banner-free!",
      message: "www.weather2umbrella.com is a banner-free page because we believe that banners are disruptive and reduce the quality of the content. Please consider donating so we can cover basic expenses.",
    },
    rs: {
      title: "Bez banera!",
      message: "www.weather2umbrella.com je sajt bez banera, jer smatramo da baneri uznemiravaju i umanjuju kvalitet prikaza sadržaja. Molimo vas, donirajte kako biste nam pomogli da pokrijemo osnovne troškove.",
    },
    hr: {
      title: "Bez banera!",
      message: "www.weather2umbrella.com je stranica bez banera, jer vjerujemo da baneri uznemiravaju i umanjuju kvalitet prikaza sadržaja. Molimo vas, donirajte kako bismo mogli pokriti osnovne troškove.",
    },
    el: {
      title: "Χωρίς διαφημίσεις!",
      message: "Το www.weather2umbrella.com είναι μια σελίδα χωρίς banners, καθώς πιστεύουμε ότι τα banners ενοχλούν και μειώνουν την ποιότητα του περιεχομένου. Παρακαλούμε, κάντε μια δωρεά ώστε να καλύψουμε τα βασικά έξοδα.",
    },
	mk: {
	  title: "Без банери!",
	  message: "www.weather2umbrella.com е страница без банери затоа што веруваме дека банерите го нарушуваат квалитетот на содржината. Ве молиме размислете за донација за да можеме да ги покриеме основните трошоци.",
	},
	al: {
	  title: "Pa bannera!",
	  message: "www.weather2umbrella.com është një faqe pa bannera sepse besojmë se bannerat janë pengues dhe e zvogëlojnë cilësinë e përmbajtjes. Ju lutemi konsideroni donacion për të na ndihmuar të mbulojmë shpenzimet bazë.",
	},
	it: {
	  title: "Senza banner!",
	  message: "www.weather2umbrella.com è una pagina senza banner perché crediamo che i banner siano fastidiosi e riducano la qualità dei contenuti. Per favore considera una donazione per aiutarci a coprire le spese di base.",
	},
	es: {
	  title: "¡Sin banners!",
	  message: "www.weather2umbrella.com es una página sin banners porque creemos que los banners son molestos y reducen la calidad del contenido. Por favor, considere hacer una donación para ayudarnos a cubrir los gastos básicos.",
	},
	de: {
	  title: "Ohne Banner!",
	  message: "www.weather2umbrella.com ist eine bannerfreie Seite, weil wir glauben, dass Banner störend sind und die Qualität der Inhalte mindern. Bitte erwägen Sie eine Spende, damit wir die Grundkosten decken können.",
	},
  };
  
  const checkAndSetCookie = () => {
    const cookie = Cookies.get('donateInfo');
    if (!cookie) {
	  setShowDonateInfo(false);
      //setShowDonateInfo(true);
      Cookies.set('donateInfo', 'shown', { expires: 2 }); 
    }
  };
  
  const getMessage = () => {
    const userLang = localStorage.getItem('userLang') || 'en';
    return messages[userLang] || messages.en;
  };
  
  useEffect(() => {
    checkAndSetCookie();
    const timer = setTimeout(() => {
      setShowDonateInfo(false);
    }, 30000); 

    return () => clearTimeout(timer); 
  }, []);
  
  const { title, message } = getMessage();
  const handleClose = () => {
      setShowDonateInfo(false);
  };
  
  
  
  const handleSwipe = (direction) => {
    const dates = Object.keys(weather.cityData.daily);
    const currentIndex = dates.indexOf(activeDate);

    if (direction === 'left' && currentIndex < dates.length - 1) {
      const newDate = dates[currentIndex + 1];
      handleDateClick(newDate);
      dateRefs.current[newDate]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    } else if (direction === 'right' && currentIndex > 0) {
      const newDate = dates[currentIndex - 1];
      handleDateClick(newDate);
      dateRefs.current[newDate]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
  });
  

  const onCitySelect = async (city) => {
    try {
      
      const response = await fetch(`/api/cityDetails?cityId=${city.id}`);
      const cityDetails = await response.json();

      if (cityDetails && cityDetails.length > 0) {
        const { timezone, latitude, longitude, wmoID } = cityDetails[0];

        // Set location details for weather forecast
        const locationData = {
          cityID: city.id,
          name: city.cn,
          countrycode: city.cc,
          latitude,
          longitude,
		  timezone: String(timezone),
		  wmoID: wmoID,
        };

        
        setLocation(locationData);

        
        localStorage.setItem('userLocation', JSON.stringify(locationData));
		
		const cityName = city.cn.replace(/ /g, '-');

        
        const sefLink = `${window.location.origin}/${cityName.toLowerCase()},${latitude},${longitude}`;
        window.history.pushState({}, '', sefLink); 

        
		setIsLoading(true);
        getWeatherForecast(city.id);

        
        updateDateTime(timezone);
      }
    } catch (error) {
      console.error('Error selecting city:', error);
    }

    
    setSearchTerm('');
    setSearchResults([]); 
  };


  const updateDateTime = (timeZone) => {
    const userLang = localStorage.getItem('userLang') || 'en'; 


	const weekdayTranslations = {
	  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	  rs: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
	  hr: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
	  el: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
	  al: ['E Diel', 'E Hënë', 'E Martë', 'E Mërkurë', 'E Enjte', 'E Premte', 'E Shtunë'],
	  mk: ['Недела', 'Понеделник', 'Вторник', 'Среда', 'Четврток', 'Петок', 'Сабота'],
	  de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	  it: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
	  es: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
	};


    const options = {
      timeZone,
      weekday: 'long', 
      day: '2-digit',  
      month: '2-digit',
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: false, 
    };

    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    const [weekdayPart, datePart, timePart] = formattedDate.split(', ');


    const weekdayIndex = new Date(date).getDay(); 
    const translatedWeekday = weekdayTranslations[userLang][weekdayIndex];


    const dateTime = `${translatedWeekday}, ${datePart}, ${timePart}`;


    setDateTime(dateTime);


    const [day, month, year] = datePart.split('/'); 
    const currentHour = timePart.split(':')[0]; 


    const currentDate = `${year}-${month}-${day}`;


    setCurrentHour(currentHour);
    setCurrentDate(currentDate);
  };

  
  const capitalizeWords = (str) => {
    return str
      .split(' ') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
      .join(' '); 
  };
  

  const openMap = () => {
    setIsMapOpen(true);
  };


  const closeMap = () => {
    setIsMapOpen(false);
  };
  
  const getSeoTitle = (countrycode, cityName) => {
	const savedLang = localStorage.getItem('userLang') || 'en';
    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const capitalizedCityName = capitalizeFirstLetter(cityName);


	const seoTranslations = {
	  en: `Weather Forecast ${capitalizedCityName} - 7 days`,
	  rs: `Vremenska Prognoza ${capitalizedCityName} - 7 dana`,
	  hr: `Vremenska prognoza ${capitalizedCityName} - 7 dana`,
	  el: `Πρόγνωση καιρού ${capitalizedCityName} - 7 ημέρες`,
	  al: `Parashikimi i motit ${capitalizedCityName} - 7 ditë`,
	  mk: `Временска прогноза ${capitalizedCityName} - 7 дена`,
	  de: `Wettervorhersage ${capitalizedCityName} - 7 Tage`,
	  it: `Previsioni meteo ${capitalizedCityName} - 7 giorni`,
	  es: `Pronóstico del tiempo ${capitalizedCityName} - 7 días`
	};

   
    return seoTranslations[savedLang] || seoTranslations['en'];
  };
  
  const setSeo = (countrycode, cityName) => {
	  
	  const savedLang = localStorage.getItem('userLang') || 'en';
	  const userLocation = JSON.parse(localStorage.getItem('userLocation')) || {};
	  const locationName = userLocation.name || '';
	  const locationLatitude = userLocation.latitude || '';
	  const locationLongitude = userLocation.longitude || '';
	  
      
      const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
      const capitalizedCityName = capitalizeFirstLetter(cityName);

      
	  const seoTranslations = {
	    en: `Weather Forecast ${capitalizedCityName} - 7 days | Weather2Umbrella LTD`,
	    rs: `Vremenska Prognoza ${capitalizedCityName} - 7 dana | Weather2Umbrella LTD`,
	    hr: `Vremenska prognoza ${capitalizedCityName} - 7 dana | Weather2Umbrella LTD`,
	    el: `Πρόγνωση καιρού ${capitalizedCityName} - 7 ημέρες | Weather2Umbrella LTD`,
	    al: `Parashikimi i motit ${capitalizedCityName} - 7 ditë | Weather2Umbrella LTD`,
	    mk: `Временска прогноза ${capitalizedCityName} - 7 дена | Weather2Umbrella LTD`,
	    de: `Wettervorhersage ${capitalizedCityName} - 7 Tage | Weather2Umbrella LTD`,
	    it: `Previsioni meteo ${capitalizedCityName} - 7 giorni | Weather2Umbrella LTD`,
	    es: `Pronóstico del tiempo ${capitalizedCityName} - 7 días | Weather2Umbrella LTD`
	  };

      
      const seoTitle = seoTranslations[savedLang] || seoTranslations['en'];
	  const canonicalUrl = `https://www.weather2umbrella.com/${locationName.toLowerCase()},${locationLatitude},${locationLongitude}`;

      
      return (
        <Helmet>
		  <html lang={savedLang} />
          <meta charset="UTF-8" />
          <meta http-equiv="content-language" content={countrycode} />
          <meta name="description" content={seoTitle} />
		  <meta name="keywords" content="πρόγνωση καιρού 7 ημέρες, Θεσσαλονίκη, Αθήνα, Χαλκιδική, Vremenska Prognoza 7 dana, Beograd, Novi Sad, Zagreb, Sarajevo, Skopje, Weather Forecast 7 days, London, New York, Rome, Paris" />
          <meta name="copyright" content="Weather2Umbrella is a registered trademark of Weather2Umbrella LTD" />
          <meta name="author" content="Weather2Umbrella LTD" />
          <meta name="application-name" content="Weather2Umbrella - 7 days Weather Forecast & Weather Maps" />
          <meta name="robots" content="index,follow" />
          <meta name="verify-v1" content="OHYCv3yPjXUwarlfJnajqs8MMggoKIjdF1+07EcuHJ0=" />
          <meta name="alexaVerifyID" content="IkE8dQag6U8_TEcTDdvCe4CKhbo" />
          <meta name="p:domain_verify" content="933810b0a8e2b49af0b4c8b597a065e1" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@W2U_LTD" />
		  <link rel="canonical" href={canonicalUrl} />
          <title>{seoTitle}</title>
        </Helmet>
      );
  };
  
  // 1.
  const setUnitsAndLanguage = useCallback((countrycode) => {
    let userLang;
    let tempUnits;
    let windSpeedUnits;

    switch (countrycode) {
      case 'rs':
        userLang = 'rs';
        break;
      case 'hr':
        userLang = 'hr';
        break;
      case 'el':
        userLang = 'el';
        break;
      case 'mk':
        userLang = 'mk';
        break;
      case 'al':
        userLang = 'al';
        break;
      case 'de':
        userLang = 'de';
        break;
      case 'it':
        userLang = 'it';
        break;
      case 'es':
        userLang = 'es';
        break;
      default:
        userLang = 'en';
        break;
    }

    if (countrycode === 'us' || countrycode === 'gb') {
      tempUnits = 'F';
    } else {
      tempUnits = 'C';
    }

    if (countrycode === 'us') {
      windSpeedUnits = 'mph';
    } else if (countrycode === 'gr') {
      windSpeedUnits = 'beaufort';
    } else {
      windSpeedUnits = 'm/s';
    }

    
    setUserLang(userLang);
    setTempUnits(tempUnits);
    setWindSpeedUnits(windSpeedUnits);

    
    localStorage.setItem('userLang', userLang);
    localStorage.setItem('tempUnits', tempUnits);
    localStorage.setItem('windSpeedUnits', windSpeedUnits);

  }, []);
  
  
  const getHistoryWeather = useCallback((wmo_id) => {
    if (!currentHour || !currentDate) {
      console.error('Current hour or date is not available.');
      return;
    }

    localStorage.removeItem('historyData');
    localStorage.removeItem('historyIcon');
    localStorage.removeItem('historyTemp');

    const endpoint = `/api/history-weather?wmo_id=${wmo_id}&date=${currentDate}`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching history weather: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (!data || Object.keys(data).length === 0) {
          console.log('No data available for the given date.');
          return;
        }

	    let historyKey = null;

	    historyKey = Object.keys(data[wmo_id] || {}).find((key) => {
	      const [dateFromData, timeFromData] = key.split(' ');
	      const hourFromData = parseInt(timeFromData.split(':')[0], 10);

	      console.log('Checking key (First Pass):', key);
	      if (dateFromData === currentDate && hourFromData === currentHour) {
	        console.log('Match found for current hour:', key);
	        return true;
	      }
	      return false;
	    });

	    if (!historyKey && currentHour >= 0) {
	      historyKey = Object.keys(data[wmo_id] || {}).find((key) => {
	        const [dateFromData, timeFromData] = key.split(' ');
	        const hourFromData = parseInt(timeFromData.split(':')[0], 10);

	        console.log('Checking key (Second Pass):', key);
	        if (dateFromData === currentDate && hourFromData === (currentHour - 1 + 24) % 24) {
	          console.log('Match found for previous hour:', key);
	          return true;
	        }
	        return false;
	      });
	    }

		if (historyKey) {
		   const { weather_type, temperature } = data[wmo_id][historyKey];
		   localStorage.setItem('historyData', JSON.stringify(data));
		   localStorage.setItem('historyIcon', weather_type);
		   localStorage.setItem('historyTemp', temperature);
		   console.log('History data stored successfully.');
		 } else {
		   console.warn('No matching hourly data found in history.');
		 }
      })
      .catch((error) => {
        console.error('Error processing history weather data:', error);
      });
  }, [currentHour, currentDate]);


  const getWeatherForecast = useCallback((cityID) => {
	const userLocation = JSON.parse(localStorage.getItem('userLocation'));
	
  	if (userLocation && userLocation.wmoID) {
  	  getHistoryWeather(userLocation.wmoID);
  	}
	
	localStorage.removeItem('weatherData');
	
    fetch(`/api/weather?cityID=${cityID}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.cityData && data.cityData.hourly && Object.keys(data.cityData.hourly).length >= 148) {
          

          if (Object.keys(data.cityData.hourly).length > 168) {
            data.cityData.hourly = Object.fromEntries(
              Object.entries(data.cityData.hourly).slice(0, 168)
            );
          }

          if (Object.keys(data.cityData.daily).length > 7) {
            data.cityData.daily = Object.fromEntries(
              Object.entries(data.cityData.daily).slice(0, 7)
            );
          }

          setWeather(data);
		  localStorage.setItem('weatherData', JSON.stringify(data));
		  
        } else {
          const newUrl = `${window.location.origin}/london,51.5,-0.116667`;
          
          //window.location.href = newUrl;
           window.history.pushState({}, '', newUrl);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching weather forecast:', error);
        setIsLoading(false);
      });
  }, [getHistoryWeather]);
  
  
  const checkForWeatherAlert = useCallback(() => {
    const notificationWeatherData = JSON.parse(localStorage.getItem('notificationWeatherData'));
    const nCityName = localStorage.getItem('notificationCityName');
    const notificationTimeZone = localStorage.getItem('notificationTimeZone');
	const userLangNot = localStorage.getItem('userLang');

    if (notificationWeatherData && notificationTimeZone) {
      
      const now = new Date();
      const options = { timeZone: notificationTimeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit' };
      const formatter = new Intl.DateTimeFormat('en-GB', options);
      const [formattedDate, formattedTime] = formatter.format(now).split(', ');

      const notificationCurrentDate = formattedDate.split('/').reverse().join('-'); 
      const notificationCurrentHour = formattedTime.slice(0, 2); 

      const hourlyEntries = Object.entries(notificationWeatherData).slice(0, 24);

      for (const [key, forecast] of hourlyEntries) {

       
        const [date, time] = key.split(' '); 
        let hour = time.slice(0, 2); 

        const [year, month, day] = date.split('-');
        const notFormattedDate = `${day}.${month}.${year}`;

        
        hour = hour.replace(/^0+/, '');
		if (hour === '') {
		  hour = '0';
		}

        
        if (date === notificationCurrentDate && parseInt(hour, 10) < parseInt(notificationCurrentHour, 10)) {
          continue; 
        }

        
        const tmp = forecast.tmp; 
        const pcp = forecast.pcp || 0;
        const icon = forecast.icon; 
        const ws = forecast.ws;
        const wd = forecast.wd;
        const uv = forecast.uv;
        const flt = forecast.flt;

        
        const alertIcon = getAlert(forecast);
        const alertMessage = getAlertText(forecast, userLangNot);

        if (alertIcon && alertMessage) {
          setAlertMessage(alertMessage); 
          setShowTooltipAlert(true); 
          setTooltipData({
            alertName: nCityName,
            date: notFormattedDate,
            hour: parseInt(hour, 10),
            message: alertMessage,
            temperature: tmp,
            precipitation: pcp,
            icon: icon,
            alertIcon: alertIcon,
            ws: ws,
            wd: wd,
            uv: uv,
            flt: flt
          });
		  
		  
		  sendNotification({
		    alertName: nCityName,
		    date: notFormattedDate,
		    hour: parseInt(hour, 10),
		    message: alertMessage,
		    temperature: tmp,
		    precipitation: pcp,
		    icon: icon,
			alertIcon: alertIcon
		  });
          break; 
        }
      }
    }
  }, []);
  
  const sendNotification = ({ alertName, date, hour, message, temperature, precipitation, icon, alertIcon }) => {
    
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notifications.");
      return;
    }

    
	if (Notification.permission === "granted") {
	  
	  let notificationBody = `${alertName}, ${date} at ${hour}h\n`;
	  notificationBody += `${message}\n`; 
	  notificationBody += `Temp: ${temperature}°\n`;

	  
	  if (precipitation > 0.5) {
	    notificationBody += `Prec: ${precipitation} mm`; 
	  }

	  const notificationOptions = {
	    icon: `/images/${alertIcon}.png`, 
	    body: notificationBody,
	    badge: '/images/ic_warning_yellow_24dp.png'
	  };

	  new Notification("W2U Weather Alert", notificationOptions);
	} else if (Notification.permission !== "denied") {
	  
	  Notification.requestPermission().then(permission => {
	    if (permission === "granted") {
	      
	      let notificationBody = `${alertName}, ${date} at ${hour}h\n`;
	      notificationBody += `${message}\n`;
	      notificationBody += `Temp: ${temperature}°\n`;

	      if (precipitation > 0.5) {
	        notificationBody += `Prec: ${precipitation} mm`;
	      }

	      const notificationOptions = {
	        icon: `/images/${alertIcon}.png`,
	        body: notificationBody,
	        badge: '/images/ic_warning_yellow_24dp.png'
	      };

	      new Notification("W2U Weather Alert", notificationOptions);
	    }
	  });
	}
  };


  const getWeatherNotification = useCallback((cityID) => {
    fetch(`/api/weather?cityID=${cityID}`)
      .then((response) => response.json())
      .then((data) => {
        
        if (data && data.cityData && data.cityData.hourly) {
          localStorage.setItem('notificationWeatherData', JSON.stringify(data.cityData.hourly));
          
		  checkForWeatherAlert();
        }
      })
      .catch((error) => {
        console.error('Error fetching weather forecast for notifications:', error);
      });
  }, [checkForWeatherAlert]);

  
  const fetchCityData = async (cityName) => {
    try {
      const response = await fetch('/api/searchCity', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cityName }),
      });

      const data = await response.json();
      setSearchResults(data); 
    } catch (error) {
      console.error('Error fetching city data:', error);
    } finally {
      //setIsSearching(false);
    }
  };
  

  const setDefaultLocation = useCallback(() => {
    const defaultLocation = {
      cityID: 802063,
      name: 'london',
      countrycode: 'gb',
      timezone: 'Europe/London',
      latitude: 51.5,
      longitude: -0.116667,
	  wmoID: 1613
    };
    setLocation(defaultLocation);
    const newUrl = `${window.location.origin}/london,51.5,-0.116667`;
    //window.location.href = newUrl;
    window.history.pushState({}, '', newUrl);
    getWeatherForecast(defaultLocation.cityID); 
  }, [getWeatherForecast]);
  
  
  const askForGeolocation = useCallback(() => {
   setIsLoading(true);
   if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        fetch(`/api/geolocation?lat=${latitude}&lon=${longitude}`)
          .then((response) => response.json())
          .then((data) => {
            const userLocation = {
              cityID: data.cityID,
              name: data.name,
              countrycode: data.countrycode,
              timezone: data.timezone,
              latitude: data.latitude,
              longitude: data.longitude,
			  wmoID: data.wmoID,
            };
			localStorage.setItem('userLocation', JSON.stringify(userLocation));
            setLocation(userLocation);
			localStorage.setItem('isMyLocationID', data.cityID);
			setIsMyLocationID(data.cityID);

            
            //const isFirstVisit = !localStorage.getItem('hasVisited');
			const isFirstVisit = localStorage.getItem('hasVisited') === 'false';
            if (isFirstVisit) {
              setUnitsAndLanguage(userLocation.countrycode);
              localStorage.setItem('hasVisited', 'true');
            }
			
			const cityName = data.name.replace(/ /g, '-');

            
            const newUrl = `${window.location.origin}/${encodeURIComponent(cityName)},${data.latitude},${data.longitude}`;
            window.history.pushState({}, '', newUrl);

            
            getWeatherForecast(data.cityID);

            
            localStorage.setItem('geolocationConsent', 'granted');
            setGeolocationConsent('granted');
			setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching geolocation data:', error);
            setDefaultLocation();  
			setIsLoading(false);
          });
      },
      () => {
        localStorage.setItem('geolocationConsent', 'denied');
        setGeolocationConsent('denied');
        setDefaultLocation();  
		setIsLoading(false);
      },
	  { timeout: 5000 } 
    );
   } else {
     // Handle case where geolocation is not supported
     console.error("Geolocation is not supported by this browser.");
     setDefaultLocation();  
     setIsLoading(false);
   }
  }, [getWeatherForecast, setDefaultLocation, setUnitsAndLanguage]);


  const checkUserGeolocation = useCallback(() => {
    const locationData = localStorage.getItem('userLocation');
        
    if (locationData) {
      const parsedLocation = JSON.parse(locationData);
      setLocation(parsedLocation);
	  
	  let locationName = parsedLocation.name;
	  if (locationName.includes(',')) {
	    locationName = locationName.split(',')[0].trim();
	  }
	  
	  const cityName = locationName.replace(/ /g, '-');
      // Kreiraj SEF link
      const newUrl = `${window.location.origin}/${encodeURIComponent(cityName)},${parsedLocation.latitude},${parsedLocation.longitude}`;
      window.history.pushState({}, '', newUrl);
      getWeatherForecast(parsedLocation.cityID); 
      setIsLoading(false);
    } else {
      askForGeolocation();  
    } 
  }, [getWeatherForecast, askForGeolocation]);

 
  const handleNewLink = useCallback((url) => {
    setIsLoading(true);

    const parts = url.split('/').pop()?.split(',');

    if (parts && (parts.length === 2 || parts.length === 3)) {
      const latitude = parseFloat(parts[parts.length - 2]);
      const longitude = parseFloat(parts[parts.length - 1]);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        let apiUrl = '';

        if (parts.length === 3) {
          const cityName = decodeURIComponent(parts[0]);
          apiUrl = `/api/convert-link?slug=${encodeURIComponent(`${cityName},${latitude},${longitude}`)}`;
        } else {
          apiUrl = `/api/geolocation?lat=${latitude}&lon=${longitude}`;
        }

      
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            setLocation({
              cityID: data.cityID,
              name: data.name,
              countrycode: data.countrycode,
              timezone: data.timezone,
              latitude: data.latitude,
              longitude: data.longitude,
			  wmoID: data.wmoID,
            });

           
            localStorage.setItem('userLocation', JSON.stringify(data));
			
			const cityName = data.name.replace(/ /g, '-');

          
            const newUrl = `${window.location.origin}/${encodeURIComponent(cityName)},${data.latitude},${data.longitude}`;
            window.history.pushState({}, '', newUrl);

         
            getWeatherForecast(data.cityID);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching location data:', error);
            setDefaultLocation();
          });
      } else {
        setDefaultLocation();
      }
    } else {
      setDefaultLocation();
    }
  }, [getWeatherForecast, setDefaultLocation]);
 


  const convertOldLink = useCallback((oldLink) => {
    setIsLoading(true);

    if (!oldLink.includes('/')) {
      setDefaultLocation();
      return;
    }

    fetch(`/api/convert-link?slug=${encodeURIComponent(oldLink)}`)
      .then((response) => response.json())
      .then((data) => {
        setLocation({
          cityID: data.cityID,
          name: data.name,
          countrycode: data.countrycode,
          timezone: data.timezone,
          latitude: data.latitude,
          longitude: data.longitude,
		  wmoID: data.wmoID,
        });

        
        localStorage.setItem('userLocation', JSON.stringify(data));
		
		const cityName = data.name.replace(/ /g, '-');

       
        const newUrl = `${window.location.origin}/${encodeURIComponent(cityName)},${data.latitude},${data.longitude}`;
        window.history.pushState({}, '', newUrl);

      
        getWeatherForecast(data.cityID);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error converting old link:', error);
        setDefaultLocation();
        setIsLoading(false);
      });
  }, [getWeatherForecast, setDefaultLocation]);


  const loadSavedPreferences = () => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const savedLang = localStorage.getItem('userLang');
      const savedTempUnits = localStorage.getItem('tempUnits');
      const savedWindSpeedUnits = localStorage.getItem('windSpeedUnits');
      const savedGeolocationConsent = localStorage.getItem('geolocationConsent');
      const savedThemeMod = localStorage.getItem('themeMod');

      if (savedLang) setUserLang(savedLang);
      if (savedTempUnits) setTempUnits(savedTempUnits);
      if (savedWindSpeedUnits) setWindSpeedUnits(savedWindSpeedUnits);
      if (savedGeolocationConsent) setGeolocationConsent(savedGeolocationConsent);
      if (savedThemeMod) setThemeMod(savedThemeMod);
    }
  };

  
  const checkThemeMode = useCallback(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const savedTheme = localStorage.getItem('themeMod');
      let themeToApply;
      if (savedTheme) {
        themeToApply = savedTheme;
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        themeToApply = 'light';
      } else {
        themeToApply = 'dark';
      }
	  localStorage.setItem('themeMod', themeToApply);
      setThemeMod(themeToApply);
      document.documentElement.classList.add(themeToApply);
    }
  }, []);

  const toggleThemeMode = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const newTheme = themeMod === 'dark' ? 'light' : 'dark';
      setThemeMod(newTheme);
      localStorage.setItem('themeMod', newTheme);
      document.documentElement.classList.toggle('dark', newTheme === 'dark');
    }
  };


 
  const checkNotificationStatus = () => {
    if (typeof window !== 'undefined') {
      const savedNotificationStatus = localStorage.getItem('notificationStatus');

      
      if (savedNotificationStatus) {
        setNotificationStatus(savedNotificationStatus);
      } else {
        console.log('Notification status not found');
      }
    }
  };


  
  const toggleNotifications = () => {
    if (notificationStatus === 'granted') {
      localStorage.setItem('notificationStatus', 'denied');
      setNotificationStatus('denied');
    } else {
      askForNotificationPermission();
      
      const notificationCityID = localStorage.getItem('notificationCityID');
      const notificationCityName = localStorage.getItem('notificationCityName');
      if (notificationCityID && notificationCityName) {
        setNotificationCityName(notificationCityName);
      }
    }
  };

  
  const askForNotificationPermission = () => {
	if (!("Notification" in window)) {
	  alert("This browser does not support desktop notifications.");
	  return;
	}
    Notification.requestPermission().then(permission => {
      localStorage.setItem('notificationStatus', permission);
      setNotificationStatus(permission);

      
      if (!localStorage.getItem('notificationWeatherData')) {
        
        localStorage.setItem('notificationCityID', location.cityID.toString());
        localStorage.setItem('notificationCityName', capitalizeWords(location.name));
        localStorage.setItem('notificationCountryCode', location.countrycode); 
        localStorage.setItem('notificationTimeZone', location.timezone); 
        localStorage.setItem('notificationWeatherData', JSON.stringify(weather.cityData.hourly));
      }
	  const notificationCityName = localStorage.getItem('notificationCityName');
	  if (notificationCityName) {
	    setNotificationCityName(notificationCityName);
	  }
    });
  };

  const handleNotificationCitySelect = (event) => {
    const selectedCity = JSON.parse(event.target.value);

    
    if (selectedCity.cityID && selectedCity.name) {
      
      localStorage.setItem('notificationCityID', selectedCity.cityID);
      localStorage.setItem('notificationCityName', selectedCity.name);
      localStorage.setItem('notificationCountryCode', selectedCity.countrycode);
      localStorage.setItem('notificationTimeZone', selectedCity.timezone);

      
      setNotificationCityName(selectedCity.name);

      
      getWeatherNotification(selectedCity.cityID);
    } else {
      console.error('Invalid city data:', selectedCity);
    }
  };
 
  
  const Tooltip = ({ alertName, date, hour, message, temperature, precipitation, icon, alertIcon, ws, wd, uv, flt, onClose }) => {
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        onClick={onClose} 
      >
        <div 
          className="bg-white p-6 rounded-lg shadow-lg text-left"
          onClick={(e) => e.stopPropagation()} 
        >
          
          <div className="flex items-center mb-2">
            <img src="/images/a05.png" alt="Warning" className="w-7 h-7 mr-2" />
            <p className="font-semibold text-base">{message}</p>
            <img src={`/images/${alertIcon}.png`} alt="Alert Icon" className="w-7 h-7 ml-2" />
          </div>
        
         
          <div className="flex justify-start mb-2 space-x-2">
            <span>{alertName}, </span>
            <span>{date}</span>
            <span>at {hour}h</span>
          </div>
        
          
	<div className="flex flex-wrap items-center space-x-2">
	  <img src={`/images/${icon}.png`} alt="Weather Icon" className="w-8 h-8" />
	  <span>
	    temp: <span style={{ color: getTemperatureColor(temperature) }}>
	      {convertTemperature(temperature, tempUnits)}
	    </span>
	  </span>
	  <span>
	    feels: <span style={{ color: getTemperatureColor(flt) }}>
	      {convertTemperature(flt, tempUnits)}
	    </span>
	  </span>
	  {uv > 7 && (
	    <span>
	      uv: <span style={{ color: getUVColor(uv) }}>{uv}</span>
	    </span>
	  )}
	  {precipitation > 0.5 && (
	    <span>
	      prec: <span className="text-blue-500">{precipitation} mm</span>
	    </span>
	  )}
	</div>
		  <div className="flex flex-wrap items-center space-x-2">
			<span>{ws ? `${convertWindSpeed(ws, windSpeedUnits)} ${getDirectionFromAngle(wd)}` : ''}</span>
		  </div>
        </div>
      </div>
    );
  };



  const checkGeolocation = useCallback(() => {
    setIsLoading(true);
    if (typeof window !== 'undefined') {
      const consent = localStorage.getItem('geolocationConsent');
      setGeolocationConsent(consent);
        
      if (consent === 'granted') {
        checkUserGeolocation();  
      } else {
	    const location = JSON.parse(localStorage.getItem('userLocation'));
	    if (location && location.name && location.countrycode) {
	      checkUserGeolocation();
	    } else if (!consent) { 
	      askForGeolocation();
	    } else {
	      setDefaultLocation();  
		  checkUserGeolocation(); 
	    }
        //setIsLoading(false);
      } 
    } 
  }, [checkUserGeolocation, setDefaultLocation, askForGeolocation]);



  
  const toggleGeolocation = () => {
	//setIsLoading(true); 
    const consent = localStorage.getItem('geolocationConsent');

    if (consent === 'granted') {
      localStorage.setItem('geolocationConsent', 'denied');
      setGeolocationConsent('denied'); 
      //setDefaultLocation(); 
	  setIsLoading(false); 
    } else {
      localStorage.setItem('geolocationConsent', 'granted');
      setGeolocationConsent('granted'); 
	  setIsLoading(false);
      //askForGeolocation();  
    }
  };

 
  const onTempUnitsChange = (event) => {
    const newTempUnits = event.target.value;
    setTempUnits(newTempUnits);
    localStorage.setItem('tempUnits', newTempUnits);
  };

  
  const onWindSpeedUnitsChange = (event) => {
    const newWindSpeedUnits = event.target.value;
    setWindSpeedUnits(newWindSpeedUnits);
    localStorage.setItem('windSpeedUnits', newWindSpeedUnits);
  };

 
  const onUserLangChange = (event) => {
    const newLang = event.target.value;
    setUserLang(newLang);
    localStorage.setItem('userLang', newLang);
  };

  
  const geolocate = useCallback(() => {
    if (geolocationConsent === 'denied' || !geolocationConsent) {
      //askForGeolocation();  
    } else if (geolocationConsent === 'granted') {
      
      askForGeolocation();
    }
  }, [geolocationConsent, askForGeolocation]);

  
  const handleDeleteCity = (city) => {
    const updatedCityList = cityList.filter((c) => c.cityID !== city.cityID); 
    setCityList(updatedCityList); 
    localStorage.setItem('cityList', JSON.stringify(updatedCityList)); 
  };
  

  const CityListTooltip = ({ cityList, onSelectCity, onDeleteCity, onClose, themeMod, tooltipRef }) => {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        onClick={onClose}
      >
        <div
          ref={tooltipRef}
          className={`p-4 rounded-lg shadow-lg w-full max-w-sm mx-4 ${
            themeMod === 'dark' ? 'bg-cardDarkBg text-white' : 'bg-white text-gray-900'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {cityList.length > 0 ? (
            <ul className="max-h-60 overflow-y-auto">
              {cityList.map((city) => (
                <li
                  key={city.cityID}
                  className={`cursor-pointer flex justify-between items-center p-2 hover:bg-gray-200 dark:hover:bg-gray-700 ${
                    themeMod === 'dark' ? 'text-white' : 'text-black'
                  }`}
                  onClick={() => onSelectCity(city)} 
                >
                  
                  <span className="w-[80%]">
                    {city.name}, {city.countrycode}
                  </span>
                  
                  <span
                    onClick={(e) => {
                      e.stopPropagation(); 
                      onDeleteCity(city);
                    }}
                    className={`cursor-pointer ml-4 ${
                      themeMod === 'dark' ? 'text-gray-400 hover:text-red-600' : 'text-gray-600 hover:text-red-800'
                    }`}
                  >
                    del
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className={themeMod === 'dark' ? 'text-gray-400' : 'text-gray-600'}>Your city list is empty.</p>
          )}
        </div>
      </div>
    );
  };
  
  const Loader = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <p className="text-gray-800">Loading data, please wait...</p>
        </div>
      </div>
    );
  };
  
  const SettingsTooltip = ({ 
    onClose, 
    themeMod, 
    toggleThemeMode, 
    userLang, 
    onUserLangChange, 
    tempUnits, 
    onTempUnitsChange, 
    windSpeedUnits, 
    onWindSpeedUnitsChange, 
    notificationCityName, 
    notificationStatus, 
    toggleNotifications, 
    handleNotificationCitySelect, 
    cityList, 
    geolocationConsent, 
    toggleGeolocation 
  }) => {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        onClick={onClose} 
      >
        <div
          className={`p-4 rounded-lg shadow-lg w-full max-w-sm mx-4 ${
            themeMod === 'dark' ? 'bg-cardDarkBg text-white' : 'bg-white text-gray-900'
          }`}
          onClick={(e) => e.stopPropagation()} 
        >
          
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Dark Mode</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={themeMod === 'dark'}
                onChange={toggleThemeMode}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>

          
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Select Language:</label>
            <select onChange={onUserLangChange} value={userLang} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="en">English</option>
              <option value="rs">Serbian</option>
              <option value="el">Greek</option>
			  <option value="mk">Macedonian</option>
			  <option value="al">Albanian</option>
			  <option value="hr">Croatian</option>
			  <option value="it">Italian</option>
			  <option value="es">Spanish</option>
			  <option value="de">German</option>
            </select>
          </div>

         
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Temperature Units:</label>
            <select onChange={onTempUnitsChange} value={tempUnits} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="C">°C</option>
              <option value="F">°F</option>
		      <option value="K">°K</option>
            </select>
          </div>

          
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Wind Speed Units:</label>
            <select onChange={onWindSpeedUnitsChange} value={windSpeedUnits} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="ms">m/s</option>
              <option value="kmh">km/h</option>
		      <option value="mph">mph</option>
		      <option value="fts">fts</option>
              <option value="beaufort">Beaufort</option>
            </select>
          </div>
		  
		  
		  <div className="border-t border-gray-300 dark:border-gray-500 my-4"></div>

          
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Enable Weather Alert ({notificationCityName ? notificationCityName : 'No city selected'})</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notificationStatus === 'granted'}
                onChange={toggleNotifications}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>

          
          <div className="mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Alert City:</label>
            <select onChange={handleNotificationCitySelect} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="">-- Choose a city --</option>
              {cityList.map((city) => (
                <option key={city.cityID} value={JSON.stringify(city)}>
                  {city.name}, {city.countrycode}
                </option>
              ))}
            </select>
          </div>

          
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Enable Geolocation</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={geolocationConsent === 'granted'}
                onChange={toggleGeolocation}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const addCityToList = async (city) => {
    const savedCities = JSON.parse(localStorage.getItem('cityList')) || [];

    
    if (!savedCities.some((savedCity) => savedCity.cityID === city.id)) {
      try {
        
        const response = await fetch(`/api/cityDetails?cityId=${city.id}`);
        const cityDetails = await response.json();

        
        if (cityDetails && cityDetails.length > 0) {
          const { latitude, longitude, timezone, wmoID } = cityDetails[0];

          const newCity = {
            cityID: city.id,
            name: city.cn,
            countrycode: city.cc,
            latitude, 
            longitude, 
            timezone: String(timezone),
			wmoID: wmoID, 
          };

          const updatedCities = [...savedCities, newCity];
          localStorage.setItem('cityList', JSON.stringify(updatedCities));
		  setCityList(updatedCities);

          alert(`${city.cn}, ${city.cc} added to city list with details!`);
        }
      } catch (error) {
        console.error("Error fetching city details:", error);
        alert("Failed to fetch city details.");
      }
    } else {
      alert(`${city.cn}, ${city.cc} is already in your city list.`);
    }
  };

 
  const handleCityListSelect = (selectedCity) => {
    localStorage.setItem('cityListId', selectedCity.cityID);
    localStorage.setItem('cityListName', selectedCity.name);
    localStorage.setItem('cityListCountrycode', selectedCity.countrycode);
    localStorage.setItem('cityListLatitude', selectedCity.latitude);
    localStorage.setItem('cityListLongitude', selectedCity.longitude);
    localStorage.setItem('cityListTimezone', selectedCity.timezone);
	localStorage.setItem('cityListWmoID', selectedCity.wmoID);
	
   
    const locationData = {
      cityID: selectedCity.cityID,
      name: selectedCity.name,
      countrycode: selectedCity.countrycode,
      latitude: selectedCity.latitude,
      longitude: selectedCity.longitude,
      timezone: selectedCity.timezone,
	  wmoID: selectedCity.wmoID,
    };

    
    localStorage.setItem('userLocation', JSON.stringify(locationData));

    
    setLocation(locationData);
	
	const cityName = selectedCity.name.replace(/ /g, '-');

    
    const sefLink = `${window.location.origin}/${cityName.toLowerCase()},${selectedCity.latitude},${selectedCity.longitude}`;
    window.history.pushState({}, '', sefLink); 
	
    setIsLoading(true);
    
    getWeatherForecast(selectedCity.cityID);

    
    setShowTooltip(false);
  };
  
  const handleDateClick = (date) => {
    setActiveDate(date);
    dateRefs.current[date]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest', 
      inline: 'center', 
    });
	
  };
  
  useEffect(() => {
    if (weather?.cityData?.daily) {
      const firstDate = Object.keys(weather.cityData.daily)[0];
      setActiveDate(firstDate);
    }
  }, [weather]);
  
  
  const getHourlyForecastForCurrentTime = useCallback(() => {
    if (!currentHour || !currentDate || !weather?.cityData?.hourly) return null;

    const forecastKeyPrefix = `${currentDate} ${currentHour}`;
    
    const forecastKey = Object.keys(weather.cityData.hourly).find(key =>
      key.startsWith(forecastKeyPrefix)
    );

    
    const forecast = forecastKey ? weather.cityData.hourly[forecastKey] : null;

    if (!forecast) {
      console.log('No forecast found for the generated key prefix.');
    } else {
      console.log('Found forecast:', forecast);
    }

    return forecast;
  }, [currentHour, currentDate, weather]);

  
  const formatDate = (dateString, formatType = 'short') => {
    const date = new Date(dateString);
    const userLang = localStorage.getItem('userLang') || 'en'; 

    
	const monthNames = {
	  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	  rs: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
	  hr: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'],
	  el: ['Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'],
	  al: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
	  mk: ['Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни', 'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'],
	  de: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
	  it: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
	  es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
	};

	const weekdayNames = {
	  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	  rs: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
	  hr: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
	  el: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
	  al: ['E Diel', 'E Hënë', 'E Martë', 'E Mërkurë', 'E Enjte', 'E Premte', 'E Shtunë'],
	  mk: ['Недела', 'Понеделник', 'Вторник', 'Среда', 'Четврток', 'Петок', 'Сабота'],
	  de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	  it: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
	  es: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
	};

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[userLang][date.getMonth()];
    const shortMonth = month.slice(0, 3); 
    const weekday = weekdayNames[userLang][date.getDay()];
    const shortWeekday = weekday.slice(0, 3); 
    const hour = date.getHours().toString().padStart(2, '0') + 'h'; 

    switch (formatType) {
      case 'short':
        return `${day}. ${shortMonth}`; 
      case 'long':
        return `${weekday} ${day}. ${month}`; 
      case 'hour':
        return hour; 
      case 'day':
        return shortWeekday; 
      default:
        return dateString; 
    }
  };
  
  
  useEffect(() => {
	 if (typeof window !== 'undefined') {
	   const currentPath = window.location.pathname.toLowerCase();

	  
	   if (currentPath === '/privacy' || currentPath === '/privacy/') {
	     window.location.replace('/privacy.html');
	     return; 
	   }
	}
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const isFirstVisit = !localStorage.getItem('hasVisited');

     
      if (isFirstVisit) {
        setUnitsAndLanguage('default');
        localStorage.setItem('hasVisited', 'false');
      }

      
      loadSavedPreferences();
      checkThemeMode();
      checkNotificationStatus();

      const currentUrl = window.location.href;
	  const parts = currentUrl.split('/').pop()?.split(',');

      
	  if (parts && (parts.length === 2 || parts.length === 3)) {
        handleNewLink(currentUrl); 
      } else if (currentUrl.includes('-')) {
        convertOldLink(currentUrl); 
      } else {
        checkGeolocation();
      }
	  
	  const savedNotificationCityName = localStorage.getItem('notificationCityName');
      
	  if (savedNotificationCityName) {
      	setNotificationCityName(savedNotificationCityName);
      }
	  
     
      const location = JSON.parse(localStorage.getItem('userLocation'));
      if (location && location.name && location.countrycode) {
        
        setSeo(location.countrycode, location.name);
      }
	  
    }
  }, [checkGeolocation,checkThemeMode,convertOldLink,handleNewLink,setUnitsAndLanguage]);
  
  useEffect(() => {
    if (location && location.timezone) {
      updateDateTime(location.timezone);
    }
  }, [userLang, location]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

   
    const timeout = setTimeout(() => {
      setShowTooltip(false);
    }, 10000); 

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(timeout); 
    };
  }, [tooltipRef]);
  
  useEffect(() => {
    
    if (location && location.timezone) {
      updateDateTime(location.timezone);
    }
  }, [location]); 
  
  useEffect(() => {
    
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 1) {
        fetchCityData(searchTerm); 
      } else {
        setSearchResults([]); 
      }
    }, 100);

    
    const handleClickOutside = (event) => {
      if (!event.target.closest('.search-container')) {
        setSearchResults([]);
      }
    };

    document.addEventListener('click', handleClickOutside);

    
    return () => {
      clearTimeout(delayDebounceFn); 
      document.removeEventListener('click', handleClickOutside); 
    };
  }, [searchTerm]);
  
  
  useEffect(() => {
    
    const notificationCityID = localStorage.getItem('notificationCityID');

    if (notificationStatus === 'granted' && notificationCityID) {
      getWeatherNotification(notificationCityID);
      //checkForWeatherAlert(); 
    }

    
    const interval = setInterval(() => {
      const notificationCityID = localStorage.getItem('notificationCityID');
      if (notificationStatus === 'granted' && notificationCityID) {
        getWeatherNotification(notificationCityID); 
		
        checkForWeatherAlert();
      }
    }, 60 * 60 * 1000); 

    
    return () => clearInterval(interval);
  }, [checkForWeatherAlert, notificationStatus, getWeatherNotification]);
 

  useEffect(() => {
    
    const storedLocationID = localStorage.getItem('isMyLocationID');

    
    if (storedLocationID) {
      setIsMyLocationID(storedLocationID);
    }
  }, []);

 
  useEffect(() => {
    const fetchWeatherData = () => {
      
      const userLocation = localStorage.getItem('userLocation');
      if (userLocation) {
        try {
          const parsedLocation = JSON.parse(userLocation);
          const cityID = parsedLocation.cityID;

          if (cityID) {
            getWeatherForecast(cityID); 
          } else {
            console.error('error ');
          }
        } catch (error) {
          console.error('error:', error);
        }
      } else {
        console.log('error');
      }
    };
    fetchWeatherData();
    
    const interval = setInterval(fetchWeatherData, 1200000);
    
    return () => clearInterval(interval);
  }, [getWeatherForecast]);
  
  useEffect(() => {
    
    const fetchMovieUrl = async () => {
      try {
        const response = await fetch('/api/get-movie');
        if (!response.ok) {
          throw new Error('Failed to fetch movie');
        }
        const data = await response.json();
    

       
        setMovieUrl(data.movieURL);

       
        const newMovieUrl = data.movieURL.replace('cld_pcp', 'tmp850mb');

       
        setMovieUrlTmp(newMovieUrl);

      } catch (error) {
        console.error('Error fetching movie:', error);
        setError('Movie not available');
      }
    };

    fetchMovieUrl();
  }, []); 
  
//  useEffect(() => {
//    ReactGA.initialize(TRACKING_ID);
//    ReactGA.pageview(window.location.pathname + window.location.search); 
//  }, []);
  
  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.error('Adsbygoogle error:', error);
    }
  }, []);
  
  useEffect(() => {
    const forecastHourly = getHourlyForecastForCurrentTime();
    setForecastForCurrentTime(forecastHourly);

  }, [getHourlyForecastForCurrentTime]);
  
  
//  useEffect(() => {
//    if (window.adsbygoogle && window.adsbygoogle.length === 0) {
//      window.adsbygoogle = window.adsbygoogle || [];
//      window.adsbygoogle.push({});
//    }
//  }, []);
  
//  useEffect(() => {
//      (window.adsbygoogle = window.adsbygoogle || []).push({});
//  }, []);
  
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error: ", e);
    }
  }, []);

  return (

<div className=" md:flex md:justify-center md:items-starts">
<div className="hidden md:flex items-start space-x-4 p-2 text-gray-800 dark:text-gray-200">
	 
	  
    {/* <Adsense
      key={getWeatherForecast}
      client="ca-pub-4238729708363408"
      slot="2697108308"
      style={{ display: 'block' }}
      format="auto"
      responsive="true"
    /> */}

</div>
    <div className=" bg-lightBackground dark:bg-darkBackground">
			  
      
			        {showDonateInfo && (
			          <div
			            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
			            onClick={handleClose}
			          >
			            <div
			              className="bg-cardLightBg dark:bg-cardDarkBg text-gray-800 dark:text-gray-200 p-6 rounded-lg shadow-2xl max-w-md w-full mx-4 flex items-start space-x-4 text-base md:text-lg"
			              onClick={(e) => e.stopPropagation()} 
			            >
			              <FaInfoCircle className="w-6 h-6 text-yellow-300 mt-1" />
			              <div>
			                <div className="text-xl font-bold mb-2">{title}</div>
			                <div className="leading-relaxed mb-2">{message}</div>
			                <div className="text-blue-400 dark:text-blue-300 text-lg">PayPal: W2U Office</div>
			              </div>
			            </div>
			          </div>
			        )}
	  
	  
	      {location && location.countrycode && location.name && setSeo(location.countrycode, location.name)}  
			
	  
	  {showTooltipAlert && tooltipData && (
	    <Tooltip
		  alertName={tooltipData.alertName}
		  date={tooltipData.date}
		  hour={tooltipData.hour}
	      message={tooltipData.message}
	      temperature={tooltipData.temperature}
	      precipitation={tooltipData.precipitation}
	      icon={tooltipData.icon}
		  alertIcon={tooltipData.alertIcon}
		  ws={tooltipData.ws}
		  wd={tooltipData.wd}
		  uv={tooltipData.uv}
		  flt={tooltipData.flt}
	      onClose={() => setShowTooltipAlert(false)}
	    />
	  )}
	  
	  

<div className="bg-lightBackground dark:bg-darkBackground">
  
  {isLoading && <Loader />}
  
  <div className="flex justify-between items-center py-2">
    <div className="flex-1 px-2"> 
  <img
    src={themeMod === 'dark' ? '/images/logowhite.png' : '/images/logodarkgray.png'}
    alt="W2U logo"
    className="h-9 lg:h-10"
    style={{ maxWidth: '100%' }}
  />
    </div>
	
	
  <p 
    className="text-red-500 dark:text-red-300 hidden md:block cursor-pointer" 
    onClick={() => setShowTooltipProMap(true)}
  >
    WeatherProMaps 
  </p>

  <button 
    className="bg-transparent text-red-400 hidden md:block" 
    onClick={() => setShowTooltipProMap(true)}
  >
    <FaMap className="w-6 h-6 ml-2" /> 
  </button>

  {showTooltipProMap && location && location.timezone && (
    <div 
      className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center"
      onClick={() => setShowTooltipProMap(false)} // Zatvara tooltip klikom na pozadinu
    >
      <div 
        className="bg-white rounded-lg w-[870px] h-[90%] relative overflow-hidden"
        onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje na klik unutar tooltipa
      >
        <div className="overflow-y-auto h-full p-4">
          <WeatherProMaps
            currentDate={currentDate}
            currentHour={currentHour}
            userLang={userLang}
			timezone={location.timezone}
          />
        </div>
      </div>
    </div>
  )}
	
	
    <div className="flex space-x-1  mr-2"> 


<button onClick={() => setShowSettingsTooltip(!showSettingsTooltip)} className="block md:hidden bg-transparent text-gray-400">
  <FaCog className="w-6 h-6" />
</button>
<div className="relative">

{showSettingsTooltip && (
  <SettingsTooltip
    onClose={() => setShowSettingsTooltip(false)}
    themeMod={themeMod}
    toggleThemeMode={toggleThemeMode}
    userLang={userLang}
    onUserLangChange={onUserLangChange}
    tempUnits={tempUnits}
    onTempUnitsChange={onTempUnitsChange}
    windSpeedUnits={windSpeedUnits}
    onWindSpeedUnitsChange={onWindSpeedUnitsChange}
    notificationCityName={notificationCityName}
    notificationStatus={notificationStatus}
    toggleNotifications={toggleNotifications}
    handleNotificationCitySelect={handleNotificationCitySelect}
    cityList={cityList}
    geolocationConsent={geolocationConsent}
    toggleGeolocation={toggleGeolocation}
  />
)}
</div>
    </div>
  </div>

  
  <div className="flex justify-between items-center mb-2">
    <div className="flex px-2 space-x-3"> 
  
<div className="relative">
  <input
    type="text"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    placeholder="Enter city name"
    className={`p-2 border border-gray-300 rounded-md md:w-60 ${
      themeMod === 'dark' ? 'bg-cardDarkBg text-white border-gray-500' : 'bg-white text-black'
    }`}
  />

  
  <ul
    className={`absolute bg-white dark:bg-cardDarkBg rounded mt-1 max-h-60 overflow-y-auto w-80 z-50 ${
      searchResults.length > 0 ? (themeMod === 'dark' ? 'border border-gray-500' : 'border border-gray-300') : ''
    }`}
  >
    {searchResults.slice(0, 50).map((city) => (
      <li
        key={city.id}
        className={`cursor-pointer p-2 flex justify-between items-center hover:bg-gray-200 md:w-80 ${
          themeMod === 'dark' ? 'hover:bg-gray-700 text-white' : 'hover:bg-gray-300 text-black'
        }`}
        onClick={() => onCitySelect(city)} 
      >
        
        <span className="w-[80%]">
          {city.cn}, {city.cc.toLowerCase()}
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation(); 
            addCityToList(city);
            setSearchTerm(''); 
          }}
          className={`mr-4  ${
            themeMod === 'dark' ? 'text-gray-400 hover:text-red-600' : 'text-gray-600 hover:text-red-800'
          }`}
        >
          add
        </button>
      </li>
    ))}
  </ul>
</div>

 
<button
  className={`bg-transparent ${geolocationConsent === 'granted' ? 'text-gray-400' : 'text-gray-300 dark:text-gray-600 cursor-not-allowed'}`}
  onClick={geolocationConsent === 'granted' ? geolocate : null}
  disabled={geolocationConsent !== 'granted'}
>
  <FaMapMarkerAlt className="w-6 h-6" />
</button>
  
  <button onClick={() => setShowTooltip(!showTooltip)} className="bg-transparent text-gray-400">
    <FaListAlt className="w-6 h-6" />
  </button>
  <div className="relative">
  
  {showTooltip && (
  <CityListTooltip
    cityList={cityList}
    onSelectCity={handleCityListSelect}
    onDeleteCity={handleDeleteCity} 
    onClose={() => setShowTooltip(false)}
    themeMod={themeMod}
    tooltipRef={tooltipRef}
  />
  )}

  
  <button onClick={() => setShowSettingsTooltip(!showSettingsTooltip)} className="hidden md:block bg-transparent text-gray-400 mt-2">
    <FaCog className="w-6 h-6" />
  </button>

 
  {showSettingsTooltip && (
    <SettingsTooltip
      onClose={() => setShowSettingsTooltip(false)}
      themeMod={themeMod}
      toggleThemeMode={toggleThemeMode}
      userLang={userLang}
      onUserLangChange={onUserLangChange}
      tempUnits={tempUnits}
      onTempUnitsChange={onTempUnitsChange}
      windSpeedUnits={windSpeedUnits}
      onWindSpeedUnitsChange={onWindSpeedUnitsChange}
      notificationCityName={notificationCityName}
      notificationStatus={notificationStatus}
      toggleNotifications={toggleNotifications}
      handleNotificationCitySelect={handleNotificationCitySelect}
      cityList={cityList}
      geolocationConsent={geolocationConsent}
      toggleGeolocation={toggleGeolocation}
    />
  )}
  </div>
    </div>
    <div className="flex space-x-2 mr-2"> 


    </div>
  </div>
</div>
	  




<div className="px-2 md:flex md:space-x-2">

{location && (
<div className=" bg-cardLightBg dark:bg-cardDarkBg p-4 rounded-lg shadow-md md:w-max md:h-max text-gray-600 dark:text-gray-300">
  
  <div className="mb-2 leading-tight md:w-[320px]">
   
	{dateTime && (
    <p className="text-gray-600 dark:text-gray-300 mb-1">{dateTime}</p>
    )}
    
    
    <div className="flex items-center mb-1 leading-tight" data-ad-format="none">
  <h2 
    className="text-xl md:text-xl font-semibold text-gray-800 dark:text-gray-200 mr-2 cursor-pointer"
    onClick={() => setShowTooltip(!showTooltip)}
  >
    {isMyLocationID === location?.cityID && (
      <FaMapMarkerAlt className="inline-block mr-2 text-blue-500" />
    )}
    {capitalizeWords(location.name)}, {location.countrycode}
  </h2>

  {showTooltip && (
    <CityListTooltip
      cityList={cityList}
      onSelectCity={handleCityListSelect}
      onDeleteCity={handleDeleteCity}
      onClose={() => setShowTooltip(false)}
      themeMod={themeMod}
      tooltipRef={tooltipRef}
    />
  )}
      <span className="text-red-500 dark:text-red-300 mr-2 ml-6 md:hidden block" onClick={openMap} data-nosnippet>WeatherPro</span>

<button className="bg-transparent text-red-400 md:hidden block" onClick={openMap}>
  <FaMap className="w-6 h-6" /> 
</button>

    </div>

   
    <div className="border-t border-gray-300 dark:border-gray-600 mt-1"></div>
  </div>

 
{forecastForCurrentTime && weather && weather.cityData && weather.cityData.hourly ? (
  <div className="flex justify-between mb-2 leading-tight">
    
    
  <div className="flex items-center">
    {(() => {
      const storedHistoryIcon = localStorage.getItem('historyIcon');
      const storedHistoryTemp = localStorage.getItem('historyTemp');

      let icon = forecastForCurrentTime.icon;
      let tmp = forecastForCurrentTime.tmp;

      if (storedHistoryIcon && storedHistoryTemp && storedHistoryIcon !== null && storedHistoryTemp !== null && storedHistoryIcon !== "null" && storedHistoryTemp !== "null") {

        let formattedIcon;
        if (parseInt(storedHistoryIcon, 10) === 21) {
          formattedIcon = 'n01';
	    } else if (parseInt(storedHistoryIcon, 10) === 1) {
	      formattedIcon = forecastForCurrentTime.icon;
        } else {
          
          const prefix = icon ? icon.charAt(0) : 'd'; 
          formattedIcon =
            storedHistoryIcon < 10
              ? `${prefix}0${storedHistoryIcon}`
              : `${prefix}${storedHistoryIcon}`;
        }

        icon = formattedIcon;
        
		const forecastTemp = tmp;
		const historyTemp = Math.round(parseFloat(storedHistoryTemp));
		const tempDifference = Math.abs(forecastTemp - historyTemp);

		if (tempDifference > 2) {
		  tmp = Math.round((forecastTemp + historyTemp) / 2);
		} else {
		  tmp = historyTemp;
		}
      }

      return (
        <>
          <img
            src={`/images/${icon}.png`}
            alt="Weather Icon"
            className="w-20 h-20"
          />
          <span
            className="ml-2 text-4xl"
            style={{
              color: tmp !== null && tmp !== undefined
                ? getTemperatureColor(tmp, themeMod === 'dark')
                : '',
            }}
          >
            {convertTemperature(tmp, tempUnits)}
          </span>
        </>
      );
    })()}
  </div>
    

    
    <div className="space-y-1 text-left ml-8">
      <div className="leading-tight">feels: <span style={{ color: forecastForCurrentTime.flt !== null && forecastForCurrentTime.flt !== undefined ? getTemperatureColor(forecastForCurrentTime.flt, themeMod === 'dark') : '' }}>
	          {convertTemperature(forecastForCurrentTime.flt, tempUnits)}
	        </span></div>
      <div className="leading-tight">UV: <span style={{ color: forecastForCurrentTime.uv ? getUVColor(forecastForCurrentTime.uv) : '-' }}>
	          {forecastForCurrentTime.uv ? forecastForCurrentTime.uv : '-'}
	        </span></div>
      <div className="leading-tight"><span>{forecastForCurrentTime.ws ? `${convertWindSpeed(forecastForCurrentTime.ws, windSpeedUnits)} ${getDirectionFromAngle(forecastForCurrentTime.wd)}` : '-'}</span></div>
      <div className="leading-tight">pcp: <span className="text-blue-400">{forecastForCurrentTime.pcp ? `${forecastForCurrentTime.pcp} mm` : '-'}</span></div>
      <div className="leading-tight">rh: <span>{forecastForCurrentTime.rh ? `${forecastForCurrentTime.rh} %` : '-'}</span></div>
    </div>
  </div>
  ) : (
    <p>Please wait! Loading data...</p>
  )}
  
{forecastForCurrentTime && weather && weather.cityData && weather.cityData.hourly ? (
  <div className="text-left mt-1 leading-tight">
<p>
  {forecastForCurrentTime.icon ? WeatherDescription.getWeatherDescription(forecastForCurrentTime.icon, userLang) : ''}
  {forecastForCurrentTime.ws ? `, ${getWindDescription(forecastForCurrentTime.ws, userLang)}` : '-'}
</p>
  </div>
) : (
  <p>If it takes too long, try reloading the page...</p>
)}
</div>
)}

{isMapOpen && location && location.cityID && location.name && location.countrycode && location.timezone && 
  location.latitude !== undefined && location.longitude !== undefined && currentDate && 
  currentHour !== undefined && currentHour !== null && (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
    <button className="close-map absolute top-4 right-4 bg-red-500 text-white p-1 rounded" onClick={closeMap}>
      x
    </button>
    
    
    <div className="w-full h-full">
      <WeatherMap 
        cityID={location.cityID}
        name={location.name}
        countrycode={location.countrycode}
        timezone={location.timezone}
        latitude={parseFloat(location.latitude)}
        longitude={parseFloat(location.longitude)}
        currentDate={currentDate}
        currentHour={currentHour}
		onFullScreenChange={handleFullScreenChange}
		userLang={userLang}
      />
    </div>
  </div>
)}


{!isMapOpen && location && location.cityID && location.name && location.countrycode && location.timezone && 
  location.latitude !== undefined && location.longitude !== undefined && currentDate && 
  currentHour !== undefined && currentHour !== null && (
  <div className="hidden md:block w-[510px] h-[242px] bg-cardLightBg dark:bg-cardDarkBg rounded-lg shadow-md relative">
    <div className="w-[493px] h-[227px] mt-2 ml-2">
      {location && (
        <WeatherMap 
          cityID={location.cityID}
          name={location.name}
          countrycode={location.countrycode}
          timezone={location.timezone}
          latitude={parseFloat(location.latitude)}
          longitude={parseFloat(location.longitude)}
          currentDate={currentDate}
          currentHour={currentHour}
		  onFullScreenChange={handleFullScreenChange}
		  userLang={userLang}
        />
      )}
    </div>

  </div>
)}

</div>

<div className="hidden md:flex max-w-[870px] mx-auto space-x-4 mt-6 bg-white rounded-lg shadow-md">
  {/* Prvi div za Android aplikaciju */}
  <div className="flex justify-center items-center w-[435px]">
    <a
      href="https://play.google.com/store/apps/details?id=com.weather2umbrella.w2uweather"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/w2u_android_app.png"
        alt="W2U Android App"
        className="max-w-full"
      />
    </a>
  </div>
  
  {/* Drugi div za iOS aplikaciju */}
  <div className="flex justify-center items-center w-[435px]">
    <a
      href="https://play.google.com/store/apps/details?id=com.weather2umbrella.w2u"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/w2u_android_app_pro_maps.png"
        alt="W2U WeatherPro App"
        className="max-w-full"
      />
    </a>
  </div>
</div>


 <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
   {location && getSeoTitle(userLang, location.name)}
 </p>

 
 <div className="px-2 md:flex md:space-x-4 md:scrollbar-hide">
   <div className="bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[870px] md:h-max md:scrollbar-hide">
     <section className="overflow-x-auto md:scrollbar-hide">
<div className="p-2 flex min-w-max space-x-4 justify-center items-center md:w-[854px] md:scrollbar-hide">
  {weather && weather.cityData && weather.cityData.daily &&
    Object.entries(weather.cityData.daily)
      .filter(([key, day]) => key && day)
      .map(([key, day]) => (
        <div
          key={key}
          ref={(el) => (dateRefs.current[key] = el)} 
          className={`p-2 text-lg text-gray-600 dark:text-gray-300 rounded-lg border border-gray-100 dark:border-gray-700 shadow-md cursor-pointer flex flex-col items-center justify-center ${
            key === activeDate
              ? 'bg-activeLightBg dark:bg-activeDarkBg'
              : 'bg-cardLightBg dark:bg-cardDarkBg'
          }`}
          onClick={() => handleDateClick(key)} 
        >
          <p className="text-l text-gray-700 dark:text-gray-200 leading-tight">
            {formatDate(key, 'day')}
          </p>
          <p className="text-l text-gray-700 dark:text-gray-200 leading-tight">
            {formatDate(key, 'short')}
          </p>
          <img
            src={`/images/${day.icon}.png`}
            alt="Weather Icon"
            className="w-12 h-12"
          />
          <p className="md:text-base space-x-2">
            <span style={{ color: day.tmax !== null && day.tmax !== undefined ? getTemperatureColor(day.tmax, themeMod === 'dark') : '' }}>
              {convertTemperature(day.tmax, tempUnits)}
            </span>
            <span style={{ color: day.tmin !== null && day.tmin !== undefined ? getTemperatureColor(day.tmin, themeMod === 'dark') : '' }}>
              {convertTemperature(day.tmin, tempUnits)}
            </span>
          </p>
          <p className="md:text-base">
            {day.ws ? `${convertWindSpeed(day.ws, windSpeedUnits)} ${getDirectionFromAngle(day.wd)}` : '-'}
          </p>
          <p className="md:text-base">
            UV: <span style={{ color: day.uv ? getUVColor(day.uv) : '' }}>
              {day.uv ? day.uv : '-'}
            </span>
          </p>
        </div>
      ))}
</div>
       
     </section>
   </div>
 </div>
	  
	  
	  <div className="flex px-2 md:flex mt-4 justify-center items-center block md:hidden">
	   <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 justify-center items-center">
	 	  
    <Adsense
      client="ca-pub-4238729708363408"
      slot="1191129528"
      style={{ display: 'inline-block', width: 336, height: 280 }}
      format=""
    />

	   </div>
	  </div>
			  
    <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
      {formatDate(activeDate, 'long')}, {location && capitalizeWords(location.name)} - Meteogram
    </p>

<div className="px-2 md:flex md:space-x-4 md:scrollbar-hide">
  <div className="bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[870px] md:h-400 overflow-x-auto md:scrollbar-hide">
      
      {weather && activeDate && (
        <WeatherChart 
          hourlyData={weather.cityData.hourly} 
          activeDate={activeDate} 
          themeMod={themeMod}
          getTemperatureColor={getTemperatureColor}
          getAlert={getAlert}
		  currentDate={currentDate}
		  currentHour={currentHour}
		  isFullScreen={isFullScreen}
		  convertTemperature={convertTemperature}
		  tempUnits={tempUnits}
        />
      )}
    </div>
  </div>

<div className="hidden md:flex justify-center items-center mt-2">
 <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 justify-center items-center md:w-[870px]">
		
      {/* <Adsense
        key={getWeatherForecast}
        client="ca-pub-4238729708363408"
        slot="6991576530"
        style={{ display: 'block' }}
        format="auto"
        responsive="true"
      /> */}

 </div>
</div>
					  

 <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
   {formatDate(activeDate, 'long')}
 </p>
<div className="px-2 md:flex md:space-x-4" {...swipeHandlers}>
  <div className="bg-cardLightBg dark:bg-cardDarkBg rounded-lg shadow-md md:w-max md:h-max">
    <section className="mt-2 md:ml-2">
      
      <div className="hidden md:flex px-2 space-x-4 text-gray-500 dark:text-gray-400 text-sm">
        <div className="md:w-[40px] flex md:justify-center md:items-center leading-tight">h</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">icon</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">tmp</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">feels</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">alerts</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">uv</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">pcp</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">humid</div>
        <div className="md:w-[110px] flex md:justify-center md:items-center leading-tight">wind</div>
      </div>

      <div className="grid grid-cols-1 gap-1">
        {weather && weather.cityData && weather.cityData.hourly && activeDate &&
          (() => {
            const dateOnly = activeDate.split(' ')[0];

            return Object.entries(weather.cityData.hourly)
              .filter(([key]) => key.startsWith(dateOnly))
              .map(([key, forecast], index) => (
                <React.Fragment key={key}>
                  
                  {index === 13 && (

							 

			  	  <div className="flex px-2 md:flex justify-center items-center block md:hidden">
			  	   <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 justify-center items-center">
						
				      <Adsense
				        client="ca-pub-4238729708363408"
				        slot="7468509677"
				        style={{ display: 'block' }}
				        format="auto"
						responsive="true"
				      />

			  	   </div>
			  	  </div>
						  

                  )}

                 
                  <div className={`p-2 md:mr-2 rounded-lg shadow-md text-gray-600 dark:text-gray-300 flex justify-between ${
                    key.startsWith(`${currentDate} ${currentHour}`)
                      ? 'bg-activeLightBg dark:bg-activeDarkBg'
                      : 'bg-cardLightBg dark:bg-cardDarkBg'
                  }`}>
                    {/* Left div: horizontally aligned data */}
                    <div className="flex items-center space-x-4">
                      <p className="font-semibold md:text-sm md:flex md:w-[40px] items-center">{formatDate(key, 'hour')}</p>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <img src={`/images/${forecast.icon}.png`} alt="Weather Icon" className="w-10 h-10" />
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <span className="text-xl" style={{ color: forecast.tmp !== null && forecast.tmp !== undefined ? getTemperatureColor(forecast.tmp, themeMod === 'dark') : '' }}>
                          {convertTemperature(forecast.tmp, tempUnits)}
                        </span>
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <span style={{ color: forecast.flt !== null && forecast.flt !== undefined ? getTemperatureColor(forecast.flt, themeMod === 'dark') : '' }}>
                          {convertTemperature(forecast.flt, tempUnits)}
                        </span>
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        {getAlert(forecast) && (
                          <img src={`/images/${getAlert(forecast)}${themeMod === 'dark' ? 'd.png' : '.png'}`} alt="Alert Icon" className="w-8 h-8" />
                        )}
                      </div>
                      <div className="hidden md:flex items-center space-x-4">
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          <span style={{ color: forecast.uv ? getUVColor(forecast.uv) : '' }}>
                            {forecast.uv ? forecast.uv : ''}
                          </span>
                        </div>
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          <span className="text-blue-400">{forecast.pcp ? `${forecast.pcp} mm` : ''}</span>
                        </div>
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          {forecast.rh ? `${forecast.rh} %` : '-'}
                        </div>
                        <div className="md:w-[110px] flex md:items-center md:justify-center">
                          {forecast.ws ? `${convertWindSpeed(forecast.ws, windSpeedUnits)} ${getDirectionFromAngle(forecast.wd)}` : ''}
                        </div>
                      </div>
                    </div>
                   
                    <div className="block md:hidden space-y-1 text-right text-sm mr-1">
                      <div className="leading-tight">
                        UV: <span style={{ color: forecast.uv ? getUVColor(forecast.uv) : '' }}>{forecast.uv ? forecast.uv : '-'}</span>
                      </div>
                      <div className="leading-tight">
                        pcp: <span className="text-blue-400">{forecast.pcp ? `${forecast.pcp} mm` : '-'}</span>
                      </div>
                      <div className="leading-tight">
                        rh: {forecast.rh ? `${forecast.rh} %` : '-'}
                      </div>
                      <div className="leading-tight">
                        {forecast.ws ? `${convertWindSpeed(forecast.ws, windSpeedUnits)} ${getDirectionFromAngle(forecast.wd)}` : '-'}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ));
          })()
        }
      </div>
    </section>
  </div>
</div>

		
<div className="flex px-2 md:flex mt-4 justify-center items-center md:justify-center md:items-center">
 <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center">
	
    {/* banner */}
	  

 </div>
</div>
		
	   
	    <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
	      W2U - Europe Clouds and Precipitation
	    </p>
<div className="flex px-2 md:flex justify-center items-center md:justify-center md:items-center" data-ad-format="none">
 <div className="flex bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[670px] md:h-max justify-center items-center md:justify-center md:items-center" data-ad-format="none">
	      <div className="movie-container">
			{error ? (
			    <p>{error}</p>
			  ) : movieUrl ? (
			    <video width="870" height="560" controls className="rounded-lg shadow-lg">
			      <source src={movieUrl} type="video/mp4" />
			      Weather2Umbrella Weather Forecast - Weather Video.
			    </video>
			  ) : (
			    <p>Loading movie...</p>
			  )}
	      </div>
 </div>
</div>
			  
		  
		  <div className="flex px-2 md:flex mt-4 justify-center items-center block md:hidden">
		   <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 justify-center items-center">
		  	
		       <Adsense
		        client="ca-pub-4238729708363408"
		        slot="7259560452"
		        style={{ display: 'block' }}
		        format="auto"
				responsive="true"
		      />
	
		   </div>
		  </div>
		  
			  
  	    <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
  	      W2U - Europe Temperature 850mb
  	    </p>
  <div className="flex px-2 md:flex justify-center items-center md:justify-center md:items-center" data-ad-format="none">
   <div className="flex bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[670px] md:h-max justify-center items-center md:justify-center md:items-center" data-ad-format="none">
  	      <div className="movie-container-temp-850mb">
  			{error ? (
  			    <p>{error}</p>
  			  ) : movieUrlTmp ? (
  			    <video width="870" height="560" controls className="rounded-lg shadow-lg">
  			      <source src={movieUrlTmp} type="video/mp4" />
  			      Weather2Umbrella Weather Forecast - Weather Maps.
  			    </video>
  			  ) : (
  			    <p>Loading movie...</p>
  			  )}
  	      </div>
   </div>
  </div>
			  
			<Footer />
		
<div className="flex px-2 md:flex mb-2 justify-center items-center md:justify-center md:items-center">
  <div className="flex flex-col bg-cardLightBg dark:bg-cardDarkBg rounded-lg shadow-md p-2 px-2 w-full md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center text-gray-700 dark:text-gray-200">
    <div>
      © Weather2Umbrella Ltd.
    </div>
    <div className="text-sm">
      Development and design by W2U Ltd Team
    </div>
  </div>
</div>

    </div>
			 
			  
		<div className="hidden md:flex items-start space-x-4 p-2 text-gray-800 dark:text-gray-200">
		    
	        {/* <Adsense
	          key={getWeatherForecast}
	          client="ca-pub-4238729708363408"
	          slot="7450741530"
	          style={{ display: 'block' }}
	          format="auto"
	          responsive="true"
	        /> */}
		  
		</div>

				
</div>
  );
};

export default App;

